export class AssetRoutes {
  static forAvatar(userId) {
    return `/assets/avatars/${userId}`;
  }
  static forDocumentUpload() {
    return `/assets/documents`;
  }
  static forDocumentDownload(documentId) {
    return `/assets/documents/${documentId}`;
  }
  static forExportPdf(url) {
    return `/assets/export/pdf?url=${encodeURIComponent(url)}`;
  }
  static forExportScreenshot(url) {
    return `/assets/export/screenshot?url=${encodeURIComponent(url)}`;
  }
}

export class WebRoutes {
  static forMyProfile() {
    return "/";
  }
  static forEngage() {
    return "/engage";
  }
  static forTrending() {
    return "/trending";
  }
  static forFeed(nodeId) {
    return `/-/${nodeId}`;
  }
  static forDocuments(nodeId) {
    return `/-/${nodeId}/documents`;
  }
  static forTasks(nodeId) {
    return `/-/${nodeId}/tasks`;
  }
  static forEvents(nodeId) {
    return `/-/${nodeId}/events`;
  }
  static forSubscribers(nodeId) {
    return `/-/${nodeId}/subscribers`;
  }
  static forRisks() {
    return "/risks";
  }
  static forRiskView(viewId) {
    if (viewId === "detailed") {
      return `/risks`;
    }
    return `/risks/${viewId}`;
  }
  static forRisk(riskId) {
    return `/risks/${riskId}`;
  }
  static forRiskAnalysis(riskId) {
    return `/risks/${riskId}`;
  }
  static forRiskTreatments(riskId) {
    return `/risks/${riskId}/treatments`;
  }
  static forRiskLinks(riskId) {
    return `/risks/${riskId}/links`;
  }
  static forTreatments() {
    return "/treatments";
  }
  static forModels() {
    return "/inventory";
  }
  static forTreatment(treatmentId) {
    return `/treatments/${treatmentId}`;
  }
  static forModel(shortCode) {
    return `/inventory/${shortCode}`;
  }
  static forItem(shortCode, itemId) {
    return `/inventory/${shortCode}/${itemId}`;
  }
  static forControls() {
    return `/controls`;
  }
  static forControl(controlId) {
    return `/controls/${controlId}`;
  }
  static forDomains() {
    return `/domains`;
  }
  static forDomain(domainId) {
    return `/domains/${domainId}`;
  }
  static forTargets() {
    return `/deployment-targets`;
  }
  static forTarget(targetId) {
    return `/deployment-targets/${targetId}`;
  }
  static forActivities() {
    return `/activities`;
  }
  static forActivity(activityId) {
    return `/activities/${activityId}`;
  }
  static forAudits() {
    return `/audits`;
  }
  static forAudit(auditId) {
    return `/audits/${auditId}`;
  }

  static forReports() {
    return `/reports`;
  }
  static forReport(reportId) {
    return `/reports/${reportId}`;
  }
}

export class ApiRoutes {
  static forTenant(tenantId) {
    return `/v1.0/tenants/${tenantId}`;
  }
  static forRoles() {
    return `/v1.0/roles`;
  }
  static forRole(roleId) {
    return `/v1.0/roles/${roleId}`;
  }
  static forMaturityLevel(controlId) {
    return `/v1.0/controls/${controlId}/maturity`;
  }
  static forUsers() {
    return `/v1.0/users`;
  }
  static forUserSearch() {
    return `/v1.0/users/search`;
  }
  static forUserResolve() {
    return `/v1.0/users/resolve`;
  }
  static forUser(userId) {
    return `/v1.0/users/${userId}`;
  }
  static forDisableUser(userId) {
    return `/v1.0/users/${userId}/disable`;
  }
  static forEnableUser(userId) {
    return `/v1.0/users/${userId}/enable`;
  }
  static forUserRoleChange(userId) {
    return `/v1.0/users/${userId}/role`;
  }
  static forOrgUsers() {
    return `/v1.0/org-users`;
  }
  static forRisks() {
    return `/v1.0/risks`;
  }
  static forRiskSearch() {
    return `/v1.0/risks/search`;
  }
  static forRiskHeatmapSummary() {
    return `/v1.0/risks/heatmap-summary`;
  }
  static forRisk(riskId) {
    return `/v1.0/risks/${riskId}`;
  }
  static forRiskAssign(riskId) {
    return `/v1.0/risks/${riskId}/assign`;
  }
  static forRiskStart(riskId) {
    return `/v1.0/risks/${riskId}/start`;
  }
  static forRiskReview(riskId) {
    return `/v1.0/risks/${riskId}/review`;
  }
  static forRiskSubmit(riskId) {
    return `/v1.0/risks/${riskId}/submit`;
  }
  static forRiskMonitor(riskId) {
    return `/v1.0/risks/${riskId}/monitor`;
  }
  static forRiskClose(riskId) {
    return `/v1.0/risks/${riskId}/close`;
  }
  static forRiskRestore(riskId) {
    return `/v1.0/risks/${riskId}/restore`;
  }
  static forRiskTreatments(riskId) {
    return `/v1.0/risks/${riskId}/treatments`;
  }
  static forRiskLinksByRisk(riskId) {
    return `/v1.0/risks/${riskId}/links`;
  }
  static forRiskLinkSuggest(riskId) {
    return `/v1.0/risks/${riskId}/links/suggest`;
  }
  static forRiskSubscribers(riskId) {
    return `/v1.0/risks/${riskId}/subscribers`;
  }
  static forRiskSubscriber(riskId, userId) {
    return `/v1.0/risks/${riskId}/subscribers/${userId}`;
  }
  static forRiskRoles() {
    return `/v1.0/risk-roles`;
  }
  static forRiskFields() {
    return `/v1.0/risk-fields`;
  }
  static forRiskTypes() {
    return `/v1.0/risk-types`;
  }
  static forRiskType(riskTypeId) {
    return `/v1.0/risk-types/${riskTypeId}`;
  }

  static forRiskScoringGuidance(riskTypeId) {
    return `/v1.0/risk-types/${riskTypeId}/scoring-guidance`;
  }

  static forRiskTypeRoles(riskTypeId) {
    return `/v1.0/risk-types/${riskTypeId}/roles`;
  }
  static forTreatments() {
    return `/v1.0/treatments`;
  }
  static forTreatmentSearch() {
    return `/v1.0/treatments/search`;
  }
  static forTreatment(treatmentId) {
    return `/v1.0/treatments/${treatmentId}`;
  }
  static forTreatmentAssign(treatmentId) {
    return `/v1.0/treatments/${treatmentId}/assign`;
  }
  static forTreatmentProgress(treatmentId) {
    return `/v1.0/treatments/${treatmentId}/progress`;
  }
  static forRiskLinks() {
    return `/v1.0/risk-links`;
  }
  static forRiskLink(linkId) {
    return `/v1.0/risk-links/${linkId}`;
  }

  static forItems() {
    return `/v1.0/items`;
  }
  static forItem(itemId) {
    return `/v1.0/items/${itemId}`;
  }

  // WatchList
  static forItemSearch() {
    return `/v1.0/items/search`;
  }
  static forItemWatchlist(itemId) {
    return `/v1.0/items/${itemId}/watchlist`;
  }
  static forItemsWatchlist(itemId) {
    return `/v1.0/items/watchlist/${itemId}`;
  }

  static forModels() {
    return `/v1.0/models`;
  }
  static forModelSearch() {
    return `/v1.0/models/search`;
  }
  static forModel(modelId) {
    return `/v1.0/models/${modelId}`;
  }
  static forModelItems(modelId) {
    return `/v1.0/models/${modelId}/items`;
  }
  static forModelShortCode(shortCode) {
    return `/v1.0/models/by-short-code/${shortCode}`;
  }

  static forControls() {
    return `/v1.0/controls`;
  }
  static forControlSearch() {
    return `/v1.0/controls/search`;
  }
  static forControl(controlId) {
    return `/v1.0/controls/${controlId}`;
  }
  static forControlAssign(controlId) {
    return `/v1.0/controls/${controlId}/assign`;
  }
  static forControlActivate(controlId) {
    return `/v1.0/controls/${controlId}/activate`;
  }
  static forControlDeploy(controlId) {
    return `/v1.0/controls/${controlId}/deploy`;
  }
  static forControlDecommission(controlId) {
    return `/v1.0/controls/${controlId}/decommission`;
  }
  static forControlRequirements(controlId) {
    return `/v1.0/controls/${controlId}/requirements`;
  }
  static forRequirements() {
    return `/v1.0/control-requirements`;
  }
  static forRequirementSearch() {
    return `/v1.0/control-requirements/search`;
  }
  static forRequirement(requirementId) {
    return `/v1.0/control-requirements/${requirementId}`;
  }
  static forControlRegistry(registryId) {
    return `/v1.0/control-registries/${registryId}`;
  }
  static forControlRegistries() {
    return `/v1.0/control-registries`;
  }
  static forImplementationGroups() {
    return `/v1.0/implementation-groups`;
  }
  static forImplementationGroup(groupId) {
    return `/v1.0/implementation-groups/${groupId}`;
  }
  static forComplianceRoles() {
    return `/v1.0/compliance-roles`;
  }
  static forComplianceFields() {
    return `/v1.0/compliance-fields`;
  }
  static forActivities() {
    return `/v1.0/control-activities`;
  }
  static forActivitySearch() {
    return `/v1.0/control-activities/search`;
  }
  static forActivity(activityId) {
    return `/v1.0/control-activities/${activityId}`;
  }
  static forActivityComplete(activityId) {
    return `/v1.0/control-activities/${activityId}/complete`;
  }
  static forActivityExpire(activityId) {
    return `/v1.0/control-activities/${activityId}/expire`;
  }
  static forActivityAssign(activityId) {
    return `/v1.0/control-activities/${activityId}/assign`;
  }
  static forDomains() {
    return `/v1.0/control-domains`;
  }
  static forDomainSearch() {
    return `/v1.0/control-domains/search`;
  }
  static forDomain(domainId) {
    return `/v1.0/control-domains/${domainId}`;
  }
  static forSubdomains() {
    return `/v1.0/control-domains/search`;
  }

  static forDeployments() {
    return `/v1.0/deployments`;
  }
  static forDeploymentSearch() {
    return `/v1.0/deployments/search`;
  }
  static forDeployment(deploymentId) {
    return `/v1.0/deployments/${deploymentId}`;
  }

  static forTargets() {
    return `/v1.0/deployment-targets`;
  }
  static forTargetSearch() {
    return `/v1.0/deployment-targets/search`;
  }
  static forTarget(targetId) {
    return `/v1.0/deployment-targets/${targetId}`;
  }
  static forTargetAssign(targetId) {
    return `/v1.0/deployment-targets/${targetId}/assign`;
  }
  /** Activity Service **/

  static forNode(nodeId) {
    return `/v1.0/nodes/${nodeId}`;
  }
  static forNodeFeed(nodeId) {
    return `/v1.0/nodes/${nodeId}/feed`;
  }
  static forNodeActivities(nodeId) {
    return `/v1.0/nodes/${nodeId}/feed/activities`;
  }
  static forNodeNotifications(nodeId) {
    return `/v1.0/nodes/${nodeId}/notifications`;
  }
  static forNodeReadReceipt(nodeId) {
    return `/v1.0/nodes/${nodeId}/read`;
  }
  static forNodeDocuments(nodeId) {
    return `/v1.0/nodes/${nodeId}/documents`;
  }
  static forNodeMessages(nodeId) {
    return `/v1.0/nodes/${nodeId}/feed/messages`;
  }

  static forDocuments() {
    return `/v1.0/documents`;
  }
  static forDocument(documentId) {
    return `/v1.0/documents/${documentId}`;
  }
  static forDocumentDownload(documentId) {
    return `/v1.0/documents/${documentId}/download`;
  }

  /** Analytics Service **/

  static forAnalyticsRoles() {
    return `/v1.0/analytics-roles`;
  }
  static forReports() {
    return `/v1.0/reports`;
  }
  static forReportSearch() {
    return `/v1.0/reports/search`;
  }
  static forReportAssign() {
    return `/v1.0/reports/assign`;
  }
  static forReportPublish() {
    return `/v1.0/reports/publish`;
  }
  static forReport(reportId) {
    return `/v1.0/reports/${reportId}`;
  }
  static forReportSettings(reportId) {
    return `/v1.0/reports/${reportId}/settings`;
  }
  static forReportPublish(reportId) {
    return `/v1.0/reports/${reportId}/publish`;
  }
  static forReportGroups() {
    return `/v1.0/report-groups`;
  }
  static forReportGroupSearch() {
    return `/v1.0/report-groups`;
  }
  static forReportGroup(groupId) {
    return `/v1.0/report-groups/${groupId}`;
  }

  static forReportRoleUserRiskRollup(reportId) {
    return `/v1.0/reports/${reportId}/data/role-user-risk-rollup`;
  }

  static forReportModelItemRiskRollup(reportId) {
    return `/v1.0/reports/${reportId}/data/model-item-risk-rollup`;
  }

  static forReportRegistryComplianceRollup(reportId) {
    return `/v1.0/reports/${reportId}/data/registry-compliance-rollup`;
  }

  static forDeploymentTargets() {
    return `/v1.0/deployment-targets`;
  }

  static forDeploymentTarget(deploymentTargetId) {
    return `/v1.0/deployment-targets/${deploymentTargetId}`;
  }

  static forDeploymentTargetsSearch() {
    return `/v1.0/deployment-targets/search`;
  }
}
