import { z } from "zod";

const CreateRoleSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: "Name is required." })
      .max(400, { message: "Name must not be longer than 400 characters." }),
    type: z.string().min(1, { message: "Permission level is required." }),
    isUnique: z.boolean(),
    appliesTo: z.array(z.string()).min(1, { message: "Applies to is required" }),
  })
  .strict();

export default CreateRoleSchema;
