export const ReportTypes = {
  ExecutiveRiskOverview: "executive-risk-overview",
  ResidualVsTargetRiskTrend: "residual-vs-target-risk-trend",
  RiskOwnershipByRole: "risk-ownership-by-role",
  RiskOwnershipByModel: "risk-ownership-by-model",
  RiskTreatmentOverview: "risk-treatment-overview",
  ComplianceDashboard: "compliance-dashboard",
  ControlFunctionOverview: "control-function-overview",
  ControlImpactOverview: "control-impact-overview",
  ControlComplianceByDomain: "control-compliance-by-domain",
  ControlMaturityByDomain: "control-maturity-by-domain",
  ControlComplianceAndMaturityTrend: "control-compliance-and-maturity-trend",
};
